function App() {
  return (
    <div className="App" style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <iframe 
        src="https://ffm.to/rn2qo8j"
        title="Embedded Content"
        style={{ width: '100%', height: '100%', border: 'none' }}
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  );
}

export default App;
